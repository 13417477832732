import { EditOutlined } from '@ant-design/icons';
import { t, Trans } from '@lingui/macro';
import {
    ConcreteForSelectInputSupplierUI,
    OrderFlowActionSupplierUI,
    OrderSupplierUI
} from '@nexploretechnology/concreting-core-client/supplier-ui-client/supplier.ui.dto';
import { Button, Card, Col, Form, Input, Row, Select, Space } from 'antd';
import { RuleObject } from 'antd/lib/form';
import moment from 'moment';
import { useContext, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import DatePicker from 'src/app-react/components/Form/DatePicker';
import TimePicker from 'src/app-react/components/Form/TimePicker';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import RawLinkButton from 'src/app-react/components/RawLinkButton/RawLinkButton';
import { SupplierContext } from 'src/app-react/providers/SupplierProvider';
import { mergeDateTime } from 'src/app-react/utils/lib';
import styles from './ComparisonForm.module.css';

const { Option } = Select;

interface ComparisonFormProps {
    companyId: string;
    projectId: string;
    concreteTypes: ConcreteForSelectInputSupplierUI[];
    order: OrderSupplierUI;
    formAsPreviousOrder?: boolean;
}

interface EditOrderForm {
    deliveryDate: moment.Moment;
    deliveryTime: moment.Moment;
    concreteQuantity: string;
    outPerHour: string;
    concreteType: string;
}

const ComparisonForm = ({ concreteTypes, order, companyId, projectId, formAsPreviousOrder }: ComparisonFormProps) => {
    const [orderDetailsForm] = Form.useForm();
    const { supplierUIClient } = useContext(SupplierContext);
    const [isFormDisable, setFormDisable] = useState(true);

    const navigate: NavigateFunction = useNavigate();

    function onEditOrder() {
        setFormDisable(false);
    }

    function returnToMainPage() {
        navigate(`/order-monitoring`);
    }

    const dateValidator = (rule: RuleObject, date: moment.Moment) => {
        if (date && date.isSameOrAfter(moment(), 'days')) {
            return Promise.resolve();
        }
        return Promise.reject(rule.message as string);
    };

    function onReset() {
        orderDetailsForm.resetFields();
        setFormDisable(true);
    }

    async function onSubmit() {
        try {
            const { deliveryDate, deliveryTime, concreteType, concreteQuantity, outPerHour }: EditOrderForm =
                await orderDetailsForm.validateFields();

            const updatedOrder: OrderSupplierUI = {
                ...order,
                deliveryDate: mergeDateTime(deliveryDate, deliveryTime).toDate(),
                concreteType: concreteTypes.find((x) => x.id === concreteType).name,
                concreteTypeId: concreteType,
                concreteQuantity: {
                    amount: parseFloat(concreteQuantity),
                    unit: order.concreteQuantity.unit
                },
                outputPerHour: {
                    amount: parseFloat(outPerHour),
                    unit: order.outputPerHour.unit
                }
            };

            supplierUIClient
                .updateChangeRequest(companyId, projectId, order.orderId, updatedOrder)
                .then((response) => {
                    if (response.isSuccess()) {
                        SuccessNotification({
                            message: t`Order updated and confirmed successfully`,
                            description: ''
                        });

                        returnToMainPage();
                    } else {
                        ErrorNotification({ message: response.getError(), description: '' });
                    }
                })
                .catch((info: any) => {
                    ErrorNotification({
                        message: info,
                        description: ''
                    });
                });
        } catch (e: any) {
            ErrorNotification({
                message: t`Form not filled correctly`,
                description: t`Please, make sure that all the fields are filled correctly`
            });
        }
    }

    async function onConfirmOrReject(action: OrderFlowActionSupplierUI) {
        supplierUIClient
            .confirmOrRejectChangeReques(companyId, projectId, order.orderId, action)
            .then((response) => {
                if (response.isSuccess()) {
                    SuccessNotification({
                        message: action === 'reject' ? t`Order rejected successfully` : t`Order confirmed successfully`,
                        description: ''
                    });

                    returnToMainPage();
                } else {
                    ErrorNotification({ message: response.getError(), description: '' });
                }
            })
            .catch((info: any) => {
                ErrorNotification({
                    message: info,
                    description: ''
                });
            });
    }

    function checkDisplayActionButtons(): JSX.Element {
        if (formAsPreviousOrder) {
            return renderBackButton();
        } else {
            return renderActionButtons();
        }
    }

    function checkDisplayEditOrderButton(): JSX.Element {
        if (formAsPreviousOrder) {
            return <label className={styles.editOrderButton}>&nbsp;</label>;
        } else {
            return (
                <RawLinkButton data-testid="edit-btn" className={styles.editOrderButton} onClick={onEditOrder}>
                    <EditOutlined /> <Trans>Edit Order</Trans>
                </RawLinkButton>
            );
        }
    }

    function renderBackButton() {
        return (
            <Row gutter={32} className={styles.buttonsRow}>
                <Col span={12}>
                    <Button data-testid="back-btn" onClick={returnToMainPage} type="default">
                        <Trans>Back</Trans>
                    </Button>
                </Col>
            </Row>
        );
    }

    function renderActionButtons(): JSX.Element {
        return (
            <Row gutter={32} className={styles.buttonsRow}>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Space size={15}>
                        <Button data-testid="confirm-btn" onClick={() => onConfirmOrReject('confirm')} type="primary">
                            <Trans>Confirm Update</Trans>
                        </Button>

                        <Button
                            data-testid="reject-btn"
                            onClick={() => onConfirmOrReject('reject')}
                            type="primary"
                            danger={true}>
                            <Trans>Reject Update</Trans>
                        </Button>
                    </Space>
                </Col>
            </Row>
        );
    }

    function renderEditButtons(): JSX.Element {
        return (
            <Row gutter={32} className={styles.buttonsRow}>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Space size={15}>
                        <Button
                            // className={styles.formButton}
                            data-testid="cancel-btn"
                            onClick={onReset}
                            type="default">
                            <Trans>Cancel</Trans>
                        </Button>

                        <Button
                            // className={styles.formButton}
                            data-testid="update-btn"
                            onClick={onSubmit}
                            type="primary">
                            <Trans>Save and confirm</Trans>
                        </Button>
                    </Space>
                </Col>
            </Row>
        );
    }

    function statusText(status: string): JSX.Element {
        switch (status) {
            case 'ORDER CONFIRMED':
                return <Trans>Order confirmed</Trans>;
            case 'ORDER CANCELLED':
                return <Trans>Order cancelled</Trans>;
            case 'UPDATE REQUESTED':
                return <Trans>Update requested</Trans>;
            case 'CANCELLATION REQUESTED':
                return <Trans>Cancellation requested</Trans>;
            case 'ORDER INQUIRED':
                return <Trans>Order inquired</Trans>;
            case 'ORDER REJECTED FINAL':
                return <Trans>Order rejected final</Trans>;
            case 'ORDER REJECTED':
                return <Trans>Order rejected</Trans>;
            case 'ORDER COMPLETED':
                return <Trans>Order completed</Trans>;
            case 'ORDER EXPIRED':
                return <Trans>Order expired</Trans>;
        }
        return <Trans></Trans>;
    }

    return (
        <>
            <div className={styles.formTitle}>
                {formAsPreviousOrder ? <Trans>Previous Order</Trans> : <Trans>Update Request</Trans>}
            </div>

            <Card>
                {checkDisplayEditOrderButton()}

                <Form
                    className={styles.form}
                    layout="vertical"
                    form={orderDetailsForm}
                    data-testid="comparisonForm"
                    disabled={isFormDisable}
                    initialValues={{
                        deliveryDate: moment(order.deliveryDate),
                        deliveryTime: moment(order.deliveryDate),
                        concreteQuantity: order.concreteQuantity.amount,
                        outPerHour: order.outputPerHour.amount,
                        concreteType: concreteTypes.find((x) => x.id === order.cataloguedConcreteTypeId)?.id
                    }}>
                    <Row gutter={32}>
                        {isFormDisable ? (
                            <Col span="12">
                                <div
                                    data-testid="concreteType-label"
                                    className="ant-row ant-form-item"
                                    style={{ marginTop: '16px' }}>
                                    <div className="ant-col ant-form-item-label">
                                        <label className="ant-form-isnbitem-required">
                                            <Trans>Concrete Type</Trans>
                                        </label>
                                    </div>
                                    <div className="ant-form-item-control-input">
                                        <div className="ant-form-item-control-input-content">
                                            {concreteTypes.find((x) => x.id === order.cataloguedConcreteTypeId)?.name ||
                                                ''}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ) : (
                            <Col span="12">
                                <Form.Item
                                    className={styles.form}
                                    label={<Trans>Concrete Type</Trans>}
                                    name="concreteType"
                                    rules={[
                                        {
                                            required: true,
                                            message: (
                                                <span data-testid="concreteTypeError">
                                                    <Trans>This field is required</Trans>
                                                </span>
                                            )
                                        }
                                    ]}>
                                    <Select
                                        placeholder={t`Select a concrete type`}
                                        data-testid="concreteType"
                                        style={{ width: 300 }}>
                                        {concreteTypes.map((concreteType) => {
                                            return (
                                                <Option
                                                    data-testid="concreteType-option"
                                                    key={concreteType.id}
                                                    value={concreteType.id}>
                                                    {concreteType.name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        )}
                        <Col span="12" className={styles.columnRow}>
                            <Trans>Additional Quantity required</Trans>
                            <label>{order.additionalQuantity ? t`Yes` : t`No`}</label>
                        </Col>
                    </Row>

                    <Row gutter={32}>
                        <Col span="12">
                            <Form.Item
                                label={<Trans>Delivery date</Trans>}
                                name="deliveryDate"
                                rules={[
                                    {
                                        required: true,
                                        message: (
                                            <span data-testid="errorDeliveryDate">
                                                <Trans>This field is required</Trans>
                                            </span>
                                        )
                                    },
                                    {
                                        message: (
                                            <span data-testid="errorDayInputDate">
                                                <Trans>Delivery date should be bigger than now</Trans>
                                            </span>
                                        ),
                                        validator: dateValidator
                                    }
                                ]}>
                                <DatePicker
                                    style={{ width: 300 }}
                                    data-testid="deliveryDate"
                                    value={moment()}
                                    placeholder={t`Enter delivery date`}
                                    projectId={projectId}
                                />
                            </Form.Item>
                        </Col>

                        <Col span="12" className={styles.columnRow}>
                            <Trans>Structural element</Trans>
                            <label>{order.structuralElement}</label>
                        </Col>
                    </Row>
                    <Row gutter={32}>
                        <Col span="12">
                            <Form.Item
                                label={<Trans>Delivery Time</Trans>}
                                name="deliveryTime"
                                rules={[
                                    {
                                        required: true,
                                        message: (
                                            <span data-testid="errorDeliveryTime">
                                                <Trans>This field is required</Trans>
                                            </span>
                                        )
                                    }
                                ]}>
                                <TimePicker
                                    style={{ width: 300 }}
                                    data-testid="deliveryTime"
                                    value={moment()}
                                    placeholder={t`Enter delivery time`}
                                    projectId={projectId}
                                />
                            </Form.Item>
                        </Col>
                        <Col span="12" className={styles.columnRow}>
                            <Trans>Unloading location</Trans>
                            <label>
                                {order.unloadingLocation.name}, {order.unloadingLocation.locationDescription},{' '}
                                {order.unloadingLocation.locationType}
                            </label>
                        </Col>
                    </Row>
                    <Row gutter={32}>
                        <Col span="12">
                            <Form.Item
                                label={<Trans>Concrete quantity</Trans>}
                                name="concreteQuantity"
                                rules={[
                                    {
                                        pattern: new RegExp(/^\d+(?:\.\d{1,2})?$/),
                                        required: true,
                                        message: (
                                            <span data-testid="errorConcreteQuantity">
                                                <Trans>This field is required</Trans>
                                            </span>
                                        )
                                    }
                                ]}>
                                <Input
                                    style={{ width: 300 }}
                                    type="number"
                                    placeholder={t`Enter concrete quantity`}
                                    data-testid="concreteQuantity"
                                    addonAfter={'m³'}
                                />
                            </Form.Item>
                        </Col>

                        <Col span="12" className={styles.columnRow}>
                            <Trans>Additional Requirements</Trans>
                            <label>{order.additionalRequirements}</label>
                        </Col>
                    </Row>
                    <Row gutter={32}>
                        <Col span="12">
                            <Form.Item
                                label={<Trans>Output per hour</Trans>}
                                name="outPerHour"
                                rules={[
                                    {
                                        pattern: new RegExp(/^\d+(?:\.\d{1,2})?$/),
                                        required: true,
                                        message: (
                                            <span data-testid="errorOutPerHour">
                                                <Trans>This field is required</Trans>
                                            </span>
                                        )
                                    }
                                ]}>
                                <Input
                                    style={{ width: 300 }}
                                    type="number"
                                    placeholder={t`Enter output per hour`}
                                    data-testid="outPerHour"
                                    addonAfter={'m³/h'}
                                />
                            </Form.Item>
                        </Col>

                        <Col span="12" className={styles.columnRow}>
                            <Trans>Order status</Trans>
                            <label>{statusText(order.status)}</label>
                        </Col>
                    </Row>
                </Form>
            </Card>

            {isFormDisable ? checkDisplayActionButtons() : renderEditButtons()}
        </>
    );
};

export default ComparisonForm;
