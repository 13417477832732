import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Trans, t } from '@lingui/macro';
import {
    ConcreteForSelectInputSupplierUI,
    InputOrderToCancel,
    OrderComparisonSupplierUI,
    OrderSupplierUI,
    ProjectInfoSupplierUI
} from '@nexploretechnology/concreting-core-client/supplier-ui-client/supplier.ui.dto';
import { Button, Col, Modal, PageHeader, Row } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import { getAuthService } from 'src/app-react/services/authService';
import { isObjectEmpty } from 'src/app-react/utils/lib';
import { SupplierContext } from '../../providers/SupplierProvider';
import ProjectInfoTable from '../OrderDetails/components/ProjectInfoTable/ProjectInfoTable';
import ComparisonForm from './components/ComparisonForm/ComparisonForm';

interface OrderComparisonProps {
    companyId: string;
    projectId: string;
    orderId: string;
}

const OrderComparisonPage = ({ companyId, projectId, orderId }: OrderComparisonProps) => {
    const { supplierUIClient } = useContext(SupplierContext);
    const [projectInfo, setProjectInfo] = useState({} as ProjectInfoSupplierUI);
    const [concreteTypes, setConcreteTypes] = useState([] as ConcreteForSelectInputSupplierUI[]);
    const [prevOrder, setPrevOrder] = useState({} as OrderSupplierUI);
    const [order, setOrder] = useState({} as OrderSupplierUI);
    const navigate: NavigateFunction = useNavigate();
    const { confirm } = Modal;

    function getData() {
        getProjectInfo();
        getConcreteTypes();
        getOrder();
    }

    function returnToMainPage() {
        navigate(`/order-monitoring`);
    }

    async function getProjectInfo() {
        supplierUIClient
            .loadProjectInformation(companyId, projectId)
            .then((response) => {
                if (response.isSuccess()) {
                    setProjectInfo(response.getEntity());
                } else {
                    ErrorNotification({ message: response.getError(), description: '' });
                }
            })
            .catch((info: any) => {
                ErrorNotification({
                    message: info,
                    description: ''
                });
            });
    }

    async function getConcreteTypes() {
        supplierUIClient
            .loadConcreteTypes(companyId, projectId)
            .then((response) => {
                if (response.isSuccess()) {
                    setConcreteTypes(response.getEntity());
                } else {
                    ErrorNotification({ message: response.getError(), description: '' });
                }
            })
            .catch((info: any) => {
                ErrorNotification({
                    message: info,
                    description: ''
                });
            });
    }

    async function getOrder() {
        supplierUIClient
            .getOrderComparison(companyId, projectId, orderId)
            .then((response) => {
                if (response.isSuccess()) {
                    setOrdersFromComparison(response.getEntity());
                } else {
                    ErrorNotification({ message: response.getError(), description: '' });
                }
            })
            .catch((info: any) => {
                ErrorNotification({
                    message: info,
                    description: ''
                });
            });
    }

    function setOrdersFromComparison(orderComparison: OrderComparisonSupplierUI) {
        const previousOrder: OrderSupplierUI = {
            orderId: orderComparison.orderId,
            concreteTypeId: orderComparison.concreteTypeId.oldValue,
            cataloguedConcreteTypeId: orderComparison.cataloguedConcreteTypeId.oldValue,
            deliveryDate: orderComparison.deliveryDate.oldValue,
            outputPerHour: orderComparison.outputPerHour.oldValue,
            structuralElement: orderComparison.structuralElement.oldValue,
            unloadingLocation: orderComparison.unloadingLocation.oldValue,
            status: orderComparison.status.oldValue,
            concreteType: orderComparison.concreteType.oldValue,
            additionalQuantity: orderComparison.additionalQuantity.oldValue,
            additionalRequirements: orderComparison.additionalRequirements.oldValue,
            concreteQuantity: orderComparison.concreteQuantity.oldValue,
            orderFlowState: ''
        };

        const currentOrder: OrderSupplierUI = {
            orderId: orderComparison.candidateOrderId,
            concreteTypeId: orderComparison.concreteTypeId.newValue,
            cataloguedConcreteTypeId: orderComparison.cataloguedConcreteTypeId.newValue,
            deliveryDate: orderComparison.deliveryDate.newValue,
            outputPerHour: orderComparison.outputPerHour.newValue,
            structuralElement: orderComparison.structuralElement.newValue,
            unloadingLocation: orderComparison.unloadingLocation.newValue,
            status: orderComparison.status.newValue,
            concreteType: orderComparison.concreteType.newValue,
            additionalQuantity: orderComparison.additionalQuantity.newValue,
            additionalRequirements: orderComparison.additionalRequirements.newValue,
            concreteQuantity: orderComparison.concreteQuantity.newValue,
            orderFlowState: ''
        };

        setPrevOrder(previousOrder);
        setOrder(currentOrder);
    }

    async function cancelOrder() {
        const inputCancel: InputOrderToCancel = {
            cancelledReasonCode: 'cancelledBySupplier',
            cancelledReasonComment: 'order cancelled by supplier',
            cancelledBy: getAuthService().user.userId
        };

        supplierUIClient
            .cancelOrder(companyId, projectId, orderId, inputCancel)
            .then((response) => {
                if (response.isSuccess()) {
                    SuccessNotification({ message: t`Order has been successfully cancelled`, description: '' });
                    returnToMainPage();
                } else {
                    ErrorNotification({ message: response.getError(), description: '' });
                }
            })
            .catch((info: any) => {
                ErrorNotification({
                    message: info,
                    description: ''
                });
            });
    }

    function displayCancelModal() {
        confirm({
            wrapClassName: 'cancel-modal',
            title: t`Cancel Order`,
            icon: <ExclamationCircleOutlined />,
            content: t`Your are about to cancel this order. This means it will not be longer available.`,
            okText: t`Confirm cancelation`,
            okType: 'primary',
            okButtonProps: { danger: true, className: 'cancel-modal-btn' },
            cancelText: 'Close',
            onOk() {
                cancelOrder();
            },
            onCancel() {}
        });
    }

    function displayCancelButton(status: string): JSX.Element[] {
        if (status !== 'ORDER COMPLETED' && status !== 'ORDER EXPIRED' && status !== 'ORDER CANCELLED') {
            return [
                <Button data-testid="cancel-btn" danger={true} type="primary" onClick={displayCancelModal}>
                    <DeleteOutlined /> <Trans>Cancel order</Trans>
                </Button>
            ];
        }

        return [];
    }

    useEffect(() => {
        getData();
        // interval to load at 20000 milliseconds
        const interval = setInterval(() => {
            getData();
        }, 20001);

        return () => clearInterval(interval);
    }, [companyId, projectId, orderId]);

    return isObjectEmpty(projectInfo) ||
        isObjectEmpty(prevOrder) ||
        isObjectEmpty(order) ||
        isObjectEmpty(concreteTypes) ? null : (
        <>
            <PageHeader
                style={{ paddingLeft: 0, paddingRight: 0 }}
                onBack={returnToMainPage}
                title={order.orderId}
                extra={displayCancelButton(order.status)}
            />
            <ProjectInfoTable projectInfo={[projectInfo]} orderId={orderId}></ProjectInfoTable>

            <Row gutter={[24, 16]}>
                <Col span={12}>
                    <ComparisonForm
                        formAsPreviousOrder={true}
                        companyId={companyId}
                        projectId={projectId}
                        concreteTypes={concreteTypes}
                        order={prevOrder}></ComparisonForm>
                </Col>
                <Col span={12}>
                    <ComparisonForm
                        companyId={companyId}
                        projectId={projectId}
                        concreteTypes={concreteTypes}
                        order={order}></ComparisonForm>
                </Col>
            </Row>
        </>
    );
};

export default OrderComparisonPage;
