import { UserManagementClient } from '@nexploretechnology/concreting-core-client/concrete/user.management-client/user.management.client';
import { UserProfile } from '@nexploretechnology/concreting-core-client/concrete/user.management-client/user.management.dto';
import { SimpleClientResponse } from '@nexploretechnology/concreting-core-client/simple.client.response';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import Keycloak from 'keycloak-js';
import { IAuthService, setAuthService } from './app-react/services/authService';
import {
  IConfigService,
  getConfigService,
  setConfigService,
} from './app-react/services/configService';

let keycloak: Keycloak.KeycloakInstance | undefined = undefined;
let profile: Keycloak.KeycloakProfile | undefined = undefined;
let authService: IAuthService | undefined = undefined;
let configService: IConfigService | undefined = undefined;
const FAKE_TOKEN: string = '';

async function loadConfig(): Promise<IConfigService> {
  if (configService) {
    return configService;
  }

  const r = await fetch('./config/config.json');
  configService = await r.json();
  setConfigService(configService);
  return configService;
}

function getKeycloak(): Keycloak.KeycloakInstance {
  if (!keycloak) {
    throw new Error('You must load the user information first');
  }
  return keycloak;
}

export function getToken(): string {
  return getKeycloak().token!;
}

export async function updateToken(): Promise<void> {
  try {
    await getKeycloak().updateToken(60);
  } catch {
    getKeycloak().logout();
  }
}

export async function loadAuth(): Promise<void> {
  if (keycloak) {
    return;
  }
  await loadConfig();
  const config = getConfigService();

  keycloak = Keycloak({
    url: config.keycloakConfig.url,
    realm: config.keycloakConfig.realm,
    clientId: config.keycloakConfig.clientId,
  });

  const success = await keycloak.init({
    onLoad: 'login-required',
    checkLoginIframe: false,
  });

  if (success) {
    // eslint-disable-next-line
    profile = await keycloak.loadUserProfile();
  }

  const axiosConfig: AxiosRequestConfig = {
    baseURL: config.coreHost + '/api/v1',
    validateStatus: (status) => status < 600,
  };
  const axiosInstance: AxiosInstance = axios.create(axiosConfig);

  axiosInstance.interceptors.request.use(async (axiosInterceptorConfig) => {
    await updateToken();
    const token = getToken();
    axiosInterceptorConfig.headers['Authorization'] = 'Bearer ' + token;
    return axiosInterceptorConfig;
  });
  const userProfile: UserProfile = await loadUserProfile(axiosInstance);

  authService = {
    user: {
      userName: userProfile.username,
      userEmail: userProfile.userEmail,
      userId: userProfile.userId,
      companies: userProfile.companies,
    },
    logout(): void {
      getKeycloak().logout();
    },

    getToken(): string {
      return FAKE_TOKEN;
    },

    getAxiosInstance(): AxiosInstance {
      return axiosInstance;
    },

    getServerPath(): string {
      return config.coreHost + '/api/v1';
    },

    getActiveCompanyId(): string {
      return userProfile.companies[0].companyId;
    },
  };

  setAuthService(authService);
}

async function loadUserProfile(
  axiosInstanceCore: AxiosInstance,
): Promise<UserProfile> {
  const client: UserManagementClient = new UserManagementClient(
    axiosInstanceCore,
  );
  try {
    const request: SimpleClientResponse<UserProfile> =
      await client.getUserProfile(FAKE_TOKEN);
    const result: UserProfile = request.getEntity();
    return result;
  } catch (err: any) {
    alert(err.message);
    throw err;
  }
}
