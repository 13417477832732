import {
    OrderMonitoringClient,
    ProjectManagementClient,
    SupplierUIClient
} from '@nexploretechnology/concreting-core-client';
import * as React from 'react';
import { getUserCompanies } from 'src/app-react/services/user.service';
import { getAuthService } from '../services/authService';
interface SupplierProviderProps {
    companyId: string;
    setCompanyId: (companyId: string) => void;
    projectManagementClient: ProjectManagementClient;
    supplierUIClient: SupplierUIClient;
    orderMonitoringClient: OrderMonitoringClient;
}

type ProviderChildren = { children: React.ReactNode };

const SupplierContext = React.createContext({} as SupplierProviderProps);

const SupplierProvider = ({ children }: ProviderChildren) => {
    const [companyId, _setCompanyId] = React.useState(getCompanyId());

    function getCompanyId() {
        const savedCompanyId: string | null = localStorage.getItem('NexploreSupplier.preferredCompany');
        const companies = getUserCompanies();

        if (savedCompanyId && companies.find((c) => c.companyId === savedCompanyId)) {
            return savedCompanyId;
        }

        return getAuthService().getActiveCompanyId();
    }

    function setCompanyId(companyId: string) {
        localStorage.setItem('NexploreSupplier.preferredCompany', companyId);
        _setCompanyId(companyId);
    }

    const value: SupplierProviderProps = {
        companyId: companyId,
        setCompanyId: setCompanyId,
        projectManagementClient: new ProjectManagementClient(getAuthService().getAxiosInstance()),
        supplierUIClient: new SupplierUIClient(getAuthService().getAxiosInstance()),
        orderMonitoringClient: new OrderMonitoringClient(getAuthService().getAxiosInstance())
    };
    return <SupplierContext.Provider value={value}>{children}</SupplierContext.Provider>;
};

export { SupplierContext, SupplierProvider };
